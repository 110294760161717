import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import styled, { injectGlobal } from 'styled-components'
import { StaticQuery, graphql } from 'gatsby'
import normalize from 'normalize.css'

injectGlobal`
  ${normalize}
  body {
    background-color: #ffffff;
    color: #323756;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-stretch: normal;
    font-weight: normal;
    line-height: normal;
  }

  #___gatsby {
    overflow-x: hidden;
  }

  * {
    box-sizing: border-box;
  }
`
const Main = styled.main`
  margin: 0 auto;
  max-width: 1030px;
  padding-top: 5.18rem;

  @media (min-width: 768px) {
    margin: 0 15px;
    overflow-x: initial;
    padding-top: 12.5rem;
  }

  @media (min-width: 1140px) {
    margin: 0 auto;
  }
`

const {
  Provider: LocaleProvider,
  Consumer: LocaleConsumer,
} = React.createContext()

const Layout = ({ children, locale }) => (
  <LocaleProvider value={locale}>
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <>
          <Helmet
            title={data.site.siteMetadata.title}
            meta={[
              { name: 'author', content: 'Beetech' },
            ]}
          >
            <html lang={locale} />
            <link rel="alternate" href="https://beetech.global" hreflang="pt-br" />
            <link rel="alternate" href="https://beetech.global/en" hreflang="en-us" />
            <link
              rel="stylesheet"
              type="text/css"
              href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.3.15/slick.css"
            />
            <link
              rel="stylesheet"
              type="text/css"
              href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.5.9/slick-theme.css"
            />
          </Helmet>
          <Main>{children}</Main>
        </>
      )}
    />
  </LocaleProvider>
)

export { LocaleConsumer }

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  path: PropTypes.string,
  locale: PropTypes.string.isRequired,
}

Layout.defaultProps = {
  path: '/',
}

export default Layout
