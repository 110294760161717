import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import styled from 'styled-components'

import locales from '../constants/locales'

const Wrapper = styled.header`
  background-color: #fff;
  display: block;
  height: 4.5em;
  left: 0;
  margin: 0;
  padding: 45px 0 53px;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 3;

  &.sticky {
    border-bottom: solid 1px rgba(50, 55, 86, 0.1);
  }
`

const Container = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-between;
  max-width: 1030px;
  padding: 0 16px;
  width: 100%;

  @media (min-width: 768px) {
    margin: 0 auto;
    padding: 0;
  }
`
const Nav = styled.nav.attrs({
  id: props => props.id,
})`
  display: none;

  @media (min-width: 768px) {
    display: inline-block;
  }
`

const Link = styled.a.attrs({
  href: props => props.href,
})`
  color: #323756;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1.5px;
  margin: 0 12px;
  padding-bottom: 4px;
  text-decoration: none;
  text-transform: uppercase;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  &:active,
  &:focus,
  &:hover,
  &.active {
    border-bottom: solid 2px #323756;
  }
`

const LangSelector = styled.div`
  display: none;

  @media (min-width: 768px) {
    display: inline-block;
  }
`

const LangItem = styled(GatsbyLink)`
  color: #323756;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1.5px;
  margin: 0 12px;
  padding-bottom: 4px;
  text-decoration: none;
  text-transform: uppercase;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  &:active,
  &:focus,
  &:hover,
  &.active {
    border-bottom: solid 2px #323756;
  }
`

const HamburguerMenuDiv = styled.div`
  display: inline-block;
  height: 24px;
  width: 24px;

  @media (min-width: 768px) {
    display: none;
  }
`

const Menuzao = styled.a.attrs({
  id: props => props.id,
})`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 2px;
  position: relative;
  width: 100%;

  span {
    border: solid 1px #323756;
    display: block;
    margin-bottom: 6px;
    transition: all 0.15s;
    width: 20px;

    &:last-child {
      margin-bottom: 0;
      width: 14px;
    }
  }

  &.active {
    span {
      position: relative;

      &:first-child {
        transform: rotate(40deg);
        top: 8px;
        width: 20px;
      }

      &:nth-child(2) {
        transform: rotate(-40deg);
        top: 0;
        width: 20px;
      }

      &:last-child {
        opacity: 0;
        transform: scale(0);
      }
    }
  }
`

const MobileNav = styled.ul.attrs({
  id: props => props.id,
})`
  background: #FFF;
  height: 100vh;
  left: 0;
  list-style: outside none;
  margin: 0;
  padding: 0;
  position: fixed;
  top: 6.15rem;
  transform: translateX(100vw);
  transition: transform 0.5s;
  width: 100%;

  &.show {
    transform: translateX(0);
  }

  @media (min-width: 768px) {
    display: none;
  }
`

const MobileNavItem = styled.li`
  display: block;
`

const MobileLink = styled(Link)`
  align-items: center;
  background: #FFF;
  display: flex;
  height: 4.5rem;
  padding: 0 30px;
  transition: all 0.15s;

  &:active,
  &:focus,
  &:hover,
  &.active {
    background-color: #eee;
    border-bottom: 0;
  }
`

class Header extends React.Component {
  constructor(props) {
    super(props)

    this.triggerMenu = this.triggerMenu.bind(this)
    this.smoothScrolling = this.smoothScrolling.bind(this)
    this.stickyMenu = this.stickyMenu.bind(this)
  }

  componentDidMount() {
    document.addEventListener('scroll', this.stickyMenu)
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.stickyMenu)
  }

  triggerMenu() {
    const mobileMenu = document.getElementById('mobile-menu')
    mobileMenu.classList.toggle('active')

    const mobileNav = document.getElementById('mobile-nav')
    mobileNav.classList.toggle('show')
  }

  stickyMenu() {
    if (window.pageYOffset >= 54) {
      // page scrolling over smartphones means the 50
      document.querySelector('header').classList.add('sticky')
    } else {
      document.querySelector('header').classList.remove('sticky')
    }
  }

  smoothScrolling(evt, event) {
    event.preventDefault()

    const element = document.querySelector(evt.getAttribute('href'))

    const OFFSET = element.id === 'about' ? 100 : 96

    window.scrollTo({
      top: element.offsetTop - OFFSET,
      behavior: 'smooth',
    })

    let allNodes = document.getElementById('desktop-nav').children
    Object.keys(allNodes).map(element =>
      allNodes[element].classList.remove('active')
    )

    allNodes = document.querySelectorAll(`#mobile-nav a`)
    Object.keys(allNodes).map(element =>
      allNodes[element].classList.remove('active')
    )

    evt.classList.add('active')

    this.triggerMenu()
  }

  render() {
    const { path } = this.props

    return (
      <Wrapper>
        <Container>
          <div style={{ margin: '8px 0 0' }}>
            <GatsbyLink to="/">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 775.05 259.55"
                height="54"
              >
                <defs>
                  <clipPath id="clip-path">
                    <path
                      fill="none"
                      d="M186.59,162.77V95.26a9.17,9.17,0,0,0-4.71-8L120.17,52.83a9.18,9.18,0,0,0-8.94,0L49.52,87.25a9.17,9.17,0,0,0-4.71,8v67.58a9.16,9.16,0,0,0,4.73,8l61.71,34.22a9.18,9.18,0,0,0,8.9,0l61.72-34.28A9.19,9.19,0,0,0,186.59,162.77Z"
                    />
                  </clipPath>
                </defs>
                <path
                  fill="#6b6baa"
                  d="M186.59,162.77V95.26a9.17,9.17,0,0,0-4.71-8L120.17,52.83a9.18,9.18,0,0,0-8.94,0L49.52,87.25a9.17,9.17,0,0,0-4.71,8v67.58a9.16,9.16,0,0,0,4.73,8l61.71,34.22a9.18,9.18,0,0,0,8.9,0l61.72-34.28A9.19,9.19,0,0,0,186.59,162.77Z"
                />
                <path
                  fill="#47ba81"
                  d="M339,132.15a21.48,21.48,0,0,1,4.13,13.42q0,11.06-8.5,17.07t-24.79,6H267.51v-85h39.85q15.06,0,23.21,5.89a19,19,0,0,1,8.14,16.22,21.35,21.35,0,0,1-2.92,11.18,19.93,19.93,0,0,1-8,7.53A22.4,22.4,0,0,1,339,132.15ZM283.31,96v23.45h22.35q8.26,0,12.7-3a9.8,9.8,0,0,0,4.43-8.68A9.94,9.94,0,0,0,318.36,99q-4.44-3-12.7-3Zm43.86,48q0-12.27-18.35-12.27H283.31v24.54h25.51Q327.17,156.26,327.17,144Z"
                />
                <path
                  fill="#47ba81"
                  d="M419.25,141.08h-50.9a17.67,17.67,0,0,0,7,11.36q5.66,4.19,14,4.19,10.68,0,17.61-7l8.14,9.35a27.32,27.32,0,0,1-11.05,7.9,43.85,43.85,0,0,1-33.9-1.58,30.52,30.52,0,0,1-12.57-11.84,33.43,33.43,0,0,1-4.44-17.19,33.89,33.89,0,0,1,4.32-17.07,30.62,30.62,0,0,1,12-11.85,35,35,0,0,1,17.25-4.25,34.08,34.08,0,0,1,16.95,4.19A29.81,29.81,0,0,1,415.31,119a35.76,35.76,0,0,1,4.19,17.55C419.5,137.63,419.41,139.13,419.25,141.08Zm-44.89-21.57A18.15,18.15,0,0,0,368.23,131H405a17.94,17.94,0,0,0-6-11.42,19.78,19.78,0,0,0-24.73-.06Z"
                />
                <path
                  fill="#47ba81"
                  d="M494.82,141.08h-50.9a17.63,17.63,0,0,0,7,11.36q5.66,4.19,14,4.19,10.69,0,17.62-7l8.14,9.35a27.42,27.42,0,0,1-11.06,7.9,43.83,43.83,0,0,1-33.89-1.58,30.61,30.61,0,0,1-12.58-11.84,33.52,33.52,0,0,1-4.43-17.19A34,34,0,0,1,433,119.15a30.71,30.71,0,0,1,12-11.85,35,35,0,0,1,17.25-4.25,34.08,34.08,0,0,1,16.95,4.19A29.72,29.72,0,0,1,490.87,119a35.65,35.65,0,0,1,4.19,17.55Q495.06,138.15,494.82,141.08Zm-44.89-21.57A18.16,18.16,0,0,0,443.79,131H480.6a17.89,17.89,0,0,0-6-11.42,18.12,18.12,0,0,0-12.39-4.37A18.34,18.34,0,0,0,449.93,119.51Z"
                />
                <path
                  fill="#333856"
                  d="M516.81,97H488.62V83.61h72.17V97H532.6v71.67H516.81Z"
                />
                <path
                  fill="#333856"
                  d="M625.3,141.08H574.4a17.63,17.63,0,0,0,7,11.36q5.65,4.19,14,4.19,10.7,0,17.62-7l8.14,9.35a27.35,27.35,0,0,1-11.06,7.9,43.83,43.83,0,0,1-33.89-1.58,30.61,30.61,0,0,1-12.58-11.84,33.52,33.52,0,0,1-4.43-17.19,34,34,0,0,1,4.31-17.07,30.71,30.71,0,0,1,12-11.85,35,35,0,0,1,17.25-4.25,34.08,34.08,0,0,1,17,4.19A29.72,29.72,0,0,1,621.35,119a35.65,35.65,0,0,1,4.19,17.55Q625.54,138.15,625.3,141.08Zm-44.89-21.57A18.16,18.16,0,0,0,574.27,131h36.81a17.89,17.89,0,0,0-6-11.42,18.12,18.12,0,0,0-12.39-4.37A18.34,18.34,0,0,0,580.41,119.51Z"
                />
                <path
                  fill="#333856"
                  d="M651.78,165.25a31.18,31.18,0,0,1-12.51-11.84,33.09,33.09,0,0,1-4.5-17.19,32.74,32.74,0,0,1,4.5-17.13,31.5,31.5,0,0,1,12.45-11.79,38,38,0,0,1,18.16-4.25,35,35,0,0,1,16.83,3.89,25.18,25.18,0,0,1,10.87,11.17l-11.66,6.81a18.5,18.5,0,0,0-7-6.74,20.38,20.38,0,0,0-23.27,3.28q-5.59,5.52-5.59,14.76T655.61,151a20.53,20.53,0,0,0,23.33,3.28,18.53,18.53,0,0,0,7-6.75l11.66,6.81a26.31,26.31,0,0,1-11,11.24,34.33,34.33,0,0,1-16.71,3.94A38,38,0,0,1,651.78,165.25Z"
                />
                <path
                  fill="#333856"
                  d="M766.59,110.22q7.41,7.17,7.41,21.26v37.17H758.82V133.42q0-8.5-4-12.81t-11.42-4.32q-8.39,0-13.25,5t-4.86,14.52v32.8H710.1V78.51h15.18v32.8a24.62,24.62,0,0,1,9.3-6.13,34,34,0,0,1,12.33-2.13Q759.18,103.05,766.59,110.22Z"
                />
                <g clipPath="url(#clip-path)">
                  <polygon
                    fill="#32368f"
                    points="115.7 50.35 44.81 89.89 115.7 129.27 186.59 89.89 115.7 50.35"
                  />
                  <polygon
                    fill="#2c328d"
                    points="186.59 89.88 186.59 168.16 115.7 207.55 115.7 129.18 186.59 89.88"
                  />
                </g>
                <line
                  fill="none"
                  stroke="#46ba88"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="11.44px"
                  x1="7.57"
                  y1="191.61"
                  x2="7.57"
                  y2="66.75"
                />
                <line
                  fill="none"
                  stroke="#46ba88"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="11.44px"
                  x1="223.83"
                  y1="191.61"
                  x2="223.83"
                  y2="66.75"
                />
                <path
                  fill="none"
                  stroke="#343355"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="11.44px"
                  d="M7.57,191.61l96.08,55.47a24.12,24.12,0,0,0,24.1,0l96.08-55.47"
                />
                <path
                  fill="none"
                  stroke="#343355"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="11.44px"
                  d="M223.83,66.75,127.75,11.28a24.12,24.12,0,0,0-24.1,0L7.57,66.75"
                />
              </svg>
            </GatsbyLink>
          </div>
          <HamburguerMenuDiv>
            <Menuzao id="mobile-menu" onClick={this.triggerMenu}>
              <span />
              <span />
              <span />
            </Menuzao>
          </HamburguerMenuDiv>
          <Nav id="desktop-nav">
            <Link
              onClick={evt => this.smoothScrolling(evt.target, evt)}
              href="#about"
            >
              {this.props.headerAbout}
            </Link>
            <Link
              onClick={evt => this.smoothScrolling(evt.target, evt)}
              href="#values"
            >
              {this.props.headerValues}
            </Link>
            <Link
              onClick={evt => this.smoothScrolling(evt.target, evt)}
              href="#products"
            >
              {this.props.headerProducts}
            </Link>
            <Link
              onClick={evt => this.smoothScrolling(evt.target, evt)}
              href="#media"
            >
              {this.props.headerNewsroom}
            </Link>
            <Link
              onClick={evt => this.smoothScrolling(evt.target, evt)}
              href="#contact"
            >
              {this.props.headerContact}
            </Link>
          </Nav>
          <LangSelector>
            {Object.keys(locales).map(key => (
              <LangItem
                key={locales[key].locale}
                to={
                  locales[key].default ? path : `/${locales[key].path}${path}`
                }
              >
                {locales[key].locale}
              </LangItem>
            ))}
          </LangSelector>
        </Container>
        <MobileNav id="mobile-nav">
          <MobileNavItem>
            <MobileLink
              onClick={evt => this.smoothScrolling(evt.target, evt)}
              href="#about"
            >
              {this.props.headerAbout}
            </MobileLink>
          </MobileNavItem>
          <MobileNavItem>
            <MobileLink
              onClick={evt => this.smoothScrolling(evt.target, evt)}
              href="#values"
            >
              {this.props.headerValues}
            </MobileLink>
          </MobileNavItem>
          <MobileNavItem>
            <MobileLink
              onClick={evt => this.smoothScrolling(evt.target, evt)}
              href="#products"
            >
              {this.props.headerProducts}
            </MobileLink>
          </MobileNavItem>
          <MobileNavItem>
            <MobileLink
              onClick={evt => this.smoothScrolling(evt.target, evt)}
              href="#media"
            >
              {this.props.headerNewsroom}
            </MobileLink>
          </MobileNavItem>
          <MobileNavItem>
            <MobileLink
              onClick={evt => this.smoothScrolling(evt.target, evt)}
              href="#contact"
            >
              {this.props.headerContact}
            </MobileLink>
          </MobileNavItem>
          {Object.keys(locales).map(key => (
            <MobileNavItem>
              <MobileLink>
                <LangItem
                  key={locales[key].locale}
                  to={
                    locales[key].default ? path : `/${locales[key].path}${path}`
                  }
                >
                  {locales[key].locale}
                </LangItem>
              </MobileLink>
            </MobileNavItem>
          ))}
        </MobileNav>
      </Wrapper>
    )
  }
}

export default Header
