import React from 'react';
import Slider from 'react-slick'

class Carousel extends React.Component {
  constructor(props) {
    super(props);

    this.state = { widthViewport: 0 };
  }

  componentDidMount() {
    this.setWidthViewport();
    window.addEventListener('resize', this.setWidthViewport)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setWidthViewport)
  }

  setWidthViewport = () => {
    this.setState({
      widthViewport: window.outerWidth
    })
  }

  render () {
    if (this.state.widthViewport > 768) {
      return (<div
        style={{
          width: '100%',
          flexDirection: 'row',
          display: 'flex',
          justifyContent: 'space-around',
        }}
      >
        {this.props.children}
      </div>)
    } else {
      return (<Slider {...this.props.settings}>{this.props.children}</Slider>)
    }
  }
}

export default Carousel