import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.section.attrs({
  id: props => props.id
})`
  margin: 0 auto;
  padding: 90px 40px 0;
  text-align: center;

  @media (min-width: 768px) {
    margin: 0 auto 45px;
    padding: 72px 0 0;
    max-width: 970px;
    width: 100%;
  }
`
const Anchor = styled.p`
  display: block;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 2px;
  margin-top: 40px;
  margin-bottom: 72px;
  opacity: 0.5;
  text-transform: uppercase;

  @media (min-width: 768px) {
    margin-bottom: 60px;
  }
`

const ValuesList = styled.dl`
  margin: 0 auto;
  text-align: left;
  max-width: 598px;
  width: 100%;

  svg {
    width: 62px;
  }

  @media (min-width: 768px) {
    svg {
      width: 82px;
    }
  }
`

const ValueItemTitle = styled.dt`
  display: inline-block;
  font-weight: 700;
  margin-left: 16px;
  padding-bottom: 10px;
  position: relative;
  vertical-align: top;
  

  &::after {
    background-color: #33c482;
    bottom: 0;
    content: '';
    display: block;
    height: 2px;
    position: absolute;
    width: 40px;
  }

  &:last-child {
    margin-bottom: 0;
  }
  
  @media (min-width: 768px) {
    margin-left: 36px;
    margin-bottom: 62px;
    margin-right: 18px;
    padding-bottom: 24px;
    width: 128px;
  }
`

const ValueItemContent = styled.dd`
  display: inline-block;
  margin-bottom: 36px;
  margin-left: 0;
  margin-top: 10px;
  
  vertical-align: top;
  

  &:last-child {
    margin-bottom: 0;
  }

  @media (min-width: 768px) {
    margin-left: 18px;
    margin-bottom: 62px;
    margin-top: 0;
    width: 316px;
  }
`

class Values extends React.Component {
  render() {
    return (
      <Wrapper {...this.props}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="65"
          height="74"
          viewBox="0 0 65 74"
        >
          <g fill="none" fillRule="nonzero" stroke="#33C482" strokeWidth="5">
            <path d="M34 3.577l27.445 15.846a2 2 0 0 1 1 1.732v31.69a2 2 0 0 1-1 1.732L34 70.423a2 2 0 0 1-2 0L4.555 54.577a2 2 0 0 1-1-1.732v-31.69a2 2 0 0 1 1-1.732L32 3.577a2 2 0 0 1 2 0z" />
            <path d="M34 20.577l12.722 7.346a2 2 0 0 1 1 1.732v14.69a2 2 0 0 1-1 1.732L34 53.423a2 2 0 0 1-2 0l-12.722-7.346a2 2 0 0 1-1-1.732v-14.69a2 2 0 0 1 1-1.732L32 20.577a2 2 0 0 1 2 0z" />
            <path
              strokeLinecap="square"
              d="M17.77 28.334l-13.493-7.77M47.5 28.5l14-8M32.954 69.5v-15"
            />
          </g>
        </svg>
        <Anchor>{this.props.valuesAnchor}</Anchor>
        <ValuesList>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="58"
            height="47"
            viewBox="0 0 58 47"
          >
            <g
              fill="none"
              fillRule="evenodd"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="3"
            >
              <path
                stroke="#333756"
                d="M24.227 19.689a8.698 8.698 0 0 0-12.276.785l-8.284 9.418a8.697 8.697 0 0 0 .787 12.277 8.698 8.698 0 0 0 12.276-.786l8.284-9.419M27.474 24.458a8.67 8.67 0 0 0 4.54 4.503 8.677 8.677 0 0 0 6.919-.027L50.42 23.9a8.698 8.698 0 0 0 4.476-11.458 8.698 8.698 0 0 0-11.458-4.476L31.95 13"
              />
              <path
                stroke="#47B980"
                d="M25 9.485V2M20.135 11.135L17 8M29 38v7.485M34 36l3.135 3.136M19.552 25.846l-5.512 5.828M42.459 18L35 20.952"
              />
            </g>
          </svg>
          <ValueItemTitle>{this.props.valuesItemFirstTitle}</ValueItemTitle>
          <ValueItemContent>
            {this.props.valuesItemFirstContent}
          </ValueItemContent>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="49"
            height="60"
            viewBox="0 0 49 60"
          >
            <defs>
              <path id="a" d="M0 58.052h47.563V0H0z" />
            </defs>
            <g fill="none" fillRule="evenodd" transform="translate(0 1)">
              <path
                stroke="#333756"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"
                d="M11.818 44.885H5.212A3.212 3.212 0 0 1 2 41.673V4.213A3.212 3.212 0 0 1 5.212 1h28.32a3.212 3.212 0 0 1 3.212 3.213v7.954M37.602 13h5.931a3.212 3.212 0 0 1 3.212 3.212v37.46a3.212 3.212 0 0 1-3.212 3.213H15.212A3.212 3.212 0 0 1 12 53.672v-7.954"
              />
              <mask id="b" fill="#fff">
                <use xlinkHref="#a" />
              </mask>
              <path
                stroke="#47B980"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"
                d="M12 45.718h24.926V13H12zM18 22h13.552M18 29h13.552M18 36h8.672"
              />
            </g>
          </svg>

          <ValueItemTitle>{this.props.valuesItemSecondTitle}</ValueItemTitle>
          <ValueItemContent>
            {this.props.valuesItemSecondContent}
          </ValueItemContent>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="81"
            height="59"
            viewBox="0 0 81 59"
          >
            <defs>
              <path id="a" d="M0 58.604h80.512V0H0z" />
            </defs>
            <g fill="none" fillRule="evenodd">
              <path
                stroke="#333756"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"
                d="M76.224 42H4.037A2.037 2.037 0 0 0 2 44.037v1.132c0 1.125.912 2.037 2.037 2.037h72.187a2.037 2.037 0 0 0 2.037-2.037v-1.132A2.037 2.037 0 0 0 76.224 42z"
              />
              <mask id="b" fill="#fff">
                <use xlinkHref="#a" />
              </mask>
              <path
                stroke="#333756"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"
                d="M9 42.48h62.992V2H9z"
              />
              <path
                stroke="#47B980"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"
                d="M19.8 34.618h38.816a1.8 1.8 0 0 0 1.801-1.8V11.8a1.8 1.8 0 0 0-1.801-1.8H19.8a1.8 1.8 0 0 0-1.8 1.8v21.018a1.8 1.8 0 0 0 1.8 1.8z"
              />
              <path
                fill="#333855"
                d="M22 16.375a1.375 1.375 0 1 0 2.752 0 1.375 1.375 0 0 0-2.752 0M27 16.375a1.375 1.375 0 1 0 2.752 0 1.375 1.375 0 0 0-2.752 0"
              />
              <path
                fill="#47B980"
                d="M32 16.375a1.375 1.375 0 1 0 2.75 0 1.375 1.375 0 0 0-2.75 0"
              />
              <path
                fill="#FEFEFE"
                d="M75.697 57.052H56.473A3.473 3.473 0 0 1 53 53.578V19.474A3.473 3.473 0 0 1 56.473 16h19.224a3.474 3.474 0 0 1 3.474 3.474v34.104a3.474 3.474 0 0 1-3.474 3.474"
              />
              <path
                stroke="#333756"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"
                d="M75.697 57.052H56.473A3.473 3.473 0 0 1 53 53.578V19.474A3.473 3.473 0 0 1 56.473 16h19.224a3.474 3.474 0 0 1 3.474 3.474v34.104a3.474 3.474 0 0 1-3.474 3.474zM79.171 52H53"
              />
              <path
                stroke="#47B980"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"
                d="M60 31h12.804M60 37h12.804M60 44h7.516"
              />
              <path
                fill="#47B980"
                d="M63.255 23.127a2.127 2.127 0 1 0-4.253 0 2.127 2.127 0 0 0 4.253 0"
              />
            </g>
          </svg>

          <ValueItemTitle>{this.props.valuesItemThirdTitle}</ValueItemTitle>
          <ValueItemContent>
            {this.props.valuesItemThirdContent}.
          </ValueItemContent>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="59"
            height="54"
            viewBox="0 0 59 54"
          >
            <defs>
              <path id="a" d="M0 .43h7.138v7.137H0z" />
              <path id="c" d="M.347.862h7.138V8H.347z" />
            </defs>
            <g fill="none" fillRule="evenodd">
              <path
                stroke="#333756"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"
                d="M27.733 26.204l-21.099.943M50.03 46.4L27.86 26.457"
              />
              <g
                stroke="#333756"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"
              >
                <path d="M54.839 15.649L27.86 26.456M27.574 26.906L4.941 4.667" />
              </g>
              <path
                stroke="#333756"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"
                d="M35.427 5.863l-7.303 20.22M27.733 26.204l-10.729 23.75"
              />
              <g transform="translate(0 -.43)">
                <mask id="b" fill="#fff">
                  <use xlinkHref="#a" />
                </mask>
                <path
                  fill="#47B980"
                  d="M7.138 3.998a3.569 3.569 0 1 0-7.138 0 3.569 3.569 0 0 0 7.138 0"
                />
              </g>
              <path
                fill="#47B980"
                d="M8.83 27.147a3.57 3.57 0 1 0-7.139.002 3.57 3.57 0 0 0 7.14-.002M39.27 5.588a3.568 3.568 0 1 0-7.139.001 3.568 3.568 0 0 0 7.138-.001M58.681 15.374a3.569 3.569 0 1 0-7.137 0 3.569 3.569 0 0 0 7.138 0M53.402 45.993a3.569 3.569 0 1 0-7.138 0 3.569 3.569 0 0 0 7.138 0"
              />
              <g transform="translate(13 45.57)">
                <mask id="d" fill="#fff">
                  <use xlinkHref="#c" />
                </mask>
                <path
                  fill="#47B980"
                  d="M7.485 4.431a3.569 3.569 0 1 0-7.138 0 3.569 3.569 0 0 0 7.138 0"
                />
              </g>
              <path
                stroke="#333756"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"
                d="M27.861 26.456l-.128-.251"
              />
              <path
                fill="#47B980"
                d="M33.287 29.16v-6.156l-5.163-3.078-5.164 3.078v6.157l5.164 3.078z"
              />
              <path
                stroke="#FEFEFE"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"
                d="M33.87 29.508v-6.851l-5.746-3.425-5.747 3.425v6.851l5.747 3.425z"
              />
            </g>
          </svg>

          <ValueItemTitle>{this.props.valuesItemFourthTitle}</ValueItemTitle>
          <ValueItemContent>
            {this.props.valuesItemFourthContent}
          </ValueItemContent>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="48"
            height="59"
            viewBox="0 0 48 59"
          >
            <defs>
              <path id="a" d="M0 58.864h47.411V0H0z" />
            </defs>
            <g fill="none" fillRule="evenodd">
              <path
                stroke="#47B980"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"
                d="M19.073 24.772v-4.345c0-1.28.91-2.912 2.053-3.577 3.985-2.32 8.13.393 8.13 4.016v3.906"
              />
              <mask id="b" fill="#fff">
                <use xlinkHref="#a" />
              </mask>
              <path
                stroke="#47B980"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"
                d="M15.876 39.127h16.406V24.772H15.876z"
              />
              <path
                stroke="#333756"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"
                d="M23.706 57.364S45.91 48.593 45.91 10.069c0 0-13.408 1.626-22.205-8.569C14.909 11.694 1.5 10.069 1.5 10.069c0 38.523 22.206 47.296 22.206 47.296z"
              />
              <path
                fill="#333756"
                d="M27 30.204a2.65 2.65 0 1 0-5.302 0 2.65 2.65 0 0 0 5.302 0"
                mask="url(#b)"
              />
              <path
                stroke="#333756"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"
                d="M24.341 30.333v3.62"
                mask="url(#b)"
              />
            </g>
          </svg>

          <ValueItemTitle>{this.props.valuesItemFifthTitle}</ValueItemTitle>
          <ValueItemContent>
            {this.props.valuesItemFifthContent}
          </ValueItemContent>
        </ValuesList>
      </Wrapper>
    )
  }
}

export default Values
