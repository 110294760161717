import React from 'react'
import styled from 'styled-components'
import { IoIosArrowRoundUp } from 'react-icons/io'

const Wrapper = styled.footer`
  background-color: #161a39;
  display: block;
  left: 0;
  margin: 70px 0 0;
  padding: 18px 24px 24px;
  position: absolute;
  width: 100vw;

  @media (min-width: 768px) {
    margin: 0;
    padding: 53px 0 40px;
  }
`

const Container = styled.div`
  color: #ffffff;

  height: 100%;

  margin: 0 auto;
  max-width: 1030px;
  width: 100%;

  p {
    margin-top: 0;
  }

  @media (min-width: 768px) {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
  }
`
const OpacityLogo = styled.div`
  display: block;
  margin-bottom: 18px;

  @media (min-width: 768px) {
    display: none;
  }
`

const Section = styled.strong`
  display: none;

  @media (min-width: 768px) {
    color: #fff;
    display: block;
    font-size: 14px;
    letter-spacing: 2px;
    margin-bottom: 36px;
    opacity: 0.35;
  }
`

const Sitemap = styled.div`
  display: none;

  @media (min-width: 768px) {
    display: inline-block;
  }
`

const WorkWithUsFooter = styled.div`
  display: block;

  @media (min-width: 768px) {
    display: inline-block;
  }
`

const MenuLink = styled.a.attrs({
  href: props => props.href
})`
  display: none;

  @media (min-width: 768px) {
    color: #ffffff;
    display: block;
    margin-bottom: 16px;
    text-decoration: none;
  }
`

const Link = styled.a.attrs({
  href: props => props.href,
  target: '_blank'
})`
  border-bottom: solid 2px #33c482;
  color: #ffffff;
  display: inline-block;
  margin: 0 auto 16px;
  padding: 4px;
  text-decoration: none;
  transition: all 0.5s;
  width: auto;

  &:hover,
  &:focus {
    background-color: #33c482;
    border-color: transparent;
  }

  &:active {
    background-color: transparent;
    border-color: #33c482
  }
`

const SitemapListing = styled.ul`
  color: #fff;
  column-count: 4;
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    margin-bottom: 16px;

    &:nth-child(even) {
      margin-bottom: 0;
    }
  }
`

const BackToTop = styled.a.attrs({
  href: props => props.href
})`
  color: #ffffff;
  display: block;
  margin: 26px 0 22px;
  text-decoration: none;

  @media (min-width: 768px) {
    display: none;
  }
`

const ArrowIcon = styled(IoIosArrowRoundUp)`
  color: #33c482;
  font-size: 16px;
  display: inline-block;
  vertical-align: bottom;
`

const Hr = styled.hr`
  border: solid 1px rgba(255, 255, 255, 0.14);
  margin: 0 auto;
  max-width: 1030px;
  width: 100%;
`

const Copyright = styled.p`
  color: #aabbcc;
  font-size: 14px;
  line-height: 1.71;
  max-width: 269px;
  opacity: 0.6;
  width: 100%;

  @media (min-width: 768px) {
    line-height: 2.86;
    margin: 16px auto;
    max-width: 1030px;
  }
`

class Footer extends React.Component {
  constructor(props) {
    super(props);

    this.smoothScrolling = this.smoothScrolling.bind(this)
  }
  
  smoothScrolling(evt, event) {
    event.preventDefault()

    const element = document
      .querySelector(evt.getAttribute('href'))

    const OFFSET = element.id === 'about' ? 100 : 96;

    window.scrollTo({
      top: (element.offsetTop - OFFSET),
      behavior: 'smooth'
    })

    const allNodes = document.getElementById('desktop-nav').children;
    Object.keys(allNodes).map(element => { // eslint-disable-line array-callback-return
      allNodes[element].classList.remove('active')

      if (evt.getAttribute('href') === allNodes[element].getAttribute('href')) {
        allNodes[element].classList.add('active')
      }
    })
    
    const mobileNav = document.getElementById('mobile-nav')
    mobileNav.classList.remove('show')
  }

  render() {
    return (
      <Wrapper>
        <Container>
          <OpacityLogo>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              height="38"
              viewBox="0 0 240 260"
            >
              <defs>
                <path
                  id="a"
                  d="M67.379 1.801L5.664 36.227a9.172 9.172 0 0 0-4.705 8.011v67.577a9.175 9.175 0 0 0 4.726 8.023l61.711 34.215a9.177 9.177 0 0 0 8.903-.004l61.72-34.289a9.176 9.176 0 0 0 4.719-8.02V44.238a9.17 9.17 0 0 0-4.706-8.011L76.318 1.801a9.173 9.173 0 0 0-8.939 0z"
                />
                <path
                  id="c"
                  d="M67.379 1.801L5.664 36.227a9.172 9.172 0 0 0-4.705 8.011v67.577a9.175 9.175 0 0 0 4.726 8.023l61.711 34.215a9.177 9.177 0 0 0 8.903-.004l61.72-34.289a9.176 9.176 0 0 0 4.719-8.02V44.238a9.17 9.17 0 0 0-4.706-8.011L76.318 1.801a9.173 9.173 0 0 0-8.939 0z"
                />
              </defs>
              <g fill="none" fillRule="evenodd" transform="translate(10 10)">
                <path
                  fill="#6d6c7a"
                  d="M179.737 155.432V87.93c0-3.326-1.8-6.392-4.705-8.012l-61.715-34.425a9.171 9.171 0 0 0-8.938 0L42.664 79.918a9.171 9.171 0 0 0-4.705 8.012v67.576a9.175 9.175 0 0 0 4.725 8.024l61.711 34.214a9.176 9.176 0 0 0 8.904-.003l61.72-34.289a9.173 9.173 0 0 0 4.718-8.02"
                />
                <g transform="translate(37 43.692)">
                  <mask id="b" fill="#fff">
                    <use xlinkHref="#a" />
                  </mask>
                  <path
                    fill="#626277"
                    d="M71.849-.679L.959 38.863l70.89 39.383 70.889-39.383z"
                    mask="url(#b)"
                  />
                </g>
                <g transform="translate(37 43.692)">
                  <mask id="d" fill="#fff">
                    <use xlinkHref="#c" />
                  </mask>
                  <path
                    fill="#494960"
                    d="M142.737 38.85v78.289l-70.889 39.383V78.154z"
                    mask="url(#d)"
                  />
                </g>
                <g
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="11.438"
                >
                  <path
                    stroke="#80808d"
                    d="M.719 184.274V59.418M216.977 184.274V59.418"
                  />
                  <path
                    stroke="#494960"
                    d="M.719 184.274l96.075 55.47a24.112 24.112 0 0 0 24.109 0l96.074-55.47M216.977 59.418L120.903 3.95a24.107 24.107 0 0 0-24.11 0L.72 59.418"
                  />
                </g>
              </g>
            </svg>
          </OpacityLogo>
          <Sitemap>
            <Section>{this.props.FooterSiteMap}</Section>
            <SitemapListing>
              <li>
                <MenuLink onClick={evt => this.smoothScrolling(evt.target, evt)} href="#about">{this.props.headerAbout}</MenuLink>
              </li>
              <li>
                <MenuLink onClick={evt => this.smoothScrolling(evt.target, evt)} href="#values">{this.props.headerValues}</MenuLink>
              </li>
              <li>
                <MenuLink onClick={evt => this.smoothScrolling(evt.target, evt)} href="#products">{this.props.headerProducts}</MenuLink>
              </li>
              <li>
                <MenuLink onClick={evt => this.smoothScrolling(evt.target, evt)} href="#media">{this.props.headerNewsroom}</MenuLink>
              </li>
              <li>
                <MenuLink onClick={evt => this.smoothScrolling(evt.target, evt)} href="#contact">{this.props.headerContact}</MenuLink>
              </li>
              <li>
                <MenuLink onClick={evt => this.smoothScrolling(evt.target, evt)} href="#___gatsby">
                  {this.props.FooterBackLink} <ArrowIcon />
                </MenuLink>
              </li>
            </SitemapListing>
          </Sitemap>
          <WorkWithUsFooter>
            <Section>{this.props.FooterWorkWithUsTitle}</Section>
            <p>{this.props.FooterWorkWithUsText}</p>
            <p>
              {this.props.FooterWorkWithUsPreLink} <Link href="https://jobs.kenoby.com/remessaonline">{this.props.FooterWorkWithUsLink}</Link>
            </p>
          </WorkWithUsFooter>
          <BackToTop onClick={evt => this.smoothScrolling(evt.target, evt)} href="#___gatsby">
            {this.props.FooterBackLink} <ArrowIcon />
          </BackToTop>
        </Container>
        <Hr />
        <Copyright>
          {this.props.FooterCopyright} 
        </Copyright>
      </Wrapper>
    )
  }
}

export default Footer
