import React from 'react'
import styled from 'styled-components'

const Card = styled.section.attrs({
  id: props => props.id
})`
  background-color: #2424e9;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 2px 45px 0 rgba(0, 0, 0, 0.1), 0 61px 67px 0 rgba(0, 0, 0, 0.05);
  margin: 0 15px 10px;
  padding: 40px 25px;
  position: relative;
  text-align: center;
  z-index: 2;

  @media (min-width: 768px) {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0 auto 10px;
    padding: 64px 100px 64px;
  }
`

const CardAside = styled.div`
  @media (min-width: 768px) {
    margin-right: 136px;
    text-align: left;
  }
`

const Anchor = styled.p`
  display: block;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1.5px;
  margin: 16px 0 28px;
  opacity: 0.5;
  text-transform: uppercase;

  @media (min-width: 768px) {
    margin: 28px 0 0;
  }
`

const Title = styled.h2`
  font-size: 1.25rem;

  @media (min-width: 768px) {
    font-size: 2.5em;
    margin-top: 16px;
    width: 235px;
  }
`

const Span = styled.span`
  color: #33c482;
`

const Content = styled.div`
  font-size: 14px;
  line-height: 2;
  text-align: left;

  p {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 1.88;
  }
`

class About extends React.Component {
  render() {
    return (
        <Card {...this.props}>
          <CardAside>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              height="61"
              viewBox="0 0 240 260"
            >
              <defs>
                <path
                  id="a"
                  d="M67.379 1.801L5.664 36.227a9.172 9.172 0 0 0-4.705 8.011v67.577a9.175 9.175 0 0 0 4.726 8.023l61.711 34.215a9.177 9.177 0 0 0 8.903-.004l61.72-34.289a9.176 9.176 0 0 0 4.719-8.02V44.238a9.17 9.17 0 0 0-4.706-8.011L76.318 1.801a9.173 9.173 0 0 0-8.939 0z"
                />
                <path
                  id="c"
                  d="M67.379 1.801L5.664 36.227a9.172 9.172 0 0 0-4.705 8.011v67.577a9.175 9.175 0 0 0 4.726 8.023l61.711 34.215a9.177 9.177 0 0 0 8.903-.004l61.72-34.289a9.176 9.176 0 0 0 4.719-8.02V44.238a9.17 9.17 0 0 0-4.706-8.011L76.318 1.801a9.173 9.173 0 0 0-8.939 0z"
                />
              </defs>
              <g fill="none" fillRule="evenodd" transform="translate(10 10)">
                <path
                  fill="#6A6AAA"
                  d="M179.737 155.432V87.93c0-3.326-1.8-6.392-4.705-8.012l-61.715-34.425a9.171 9.171 0 0 0-8.938 0L42.664 79.918a9.171 9.171 0 0 0-4.705 8.012v67.576a9.175 9.175 0 0 0 4.725 8.024l61.711 34.214a9.176 9.176 0 0 0 8.904-.003l61.72-34.289a9.173 9.173 0 0 0 4.718-8.02"
                />
                <g transform="translate(37 43.692)">
                  <mask id="b" fill="#fff">
                    <use xlinkHref="#a" />
                  </mask>
                  <path
                    fill="#313191"
                    d="M71.849-.679L.959 38.863l70.89 39.383 70.889-39.383z"
                    mask="url(#b)"
                  />
                </g>
                <g transform="translate(37 43.692)">
                  <mask id="d" fill="#fff">
                    <use xlinkHref="#c" />
                  </mask>
                  <path
                    fill="#1F1F99"
                    d="M142.737 38.85v78.289l-70.889 39.383V78.154z"
                    mask="url(#d)"
                  />
                </g>
                <g
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="11.438"
                >
                  <path
                    stroke="#33C482"
                    d="M.719 184.274V59.418M216.977 184.274V59.418"
                  />
                  <path
                    stroke="#335"
                    d="M.719 184.274l96.075 55.47a24.112 24.112 0 0 0 24.109 0l96.074-55.47M216.977 59.418L120.903 3.95a24.107 24.107 0 0 0-24.11 0L.72 59.418"
                  />
                </g>
              </g>
            </svg>

            <Anchor>{this.props.aboutAnchor}</Anchor>
            <Title>{this.props.aboutTitle} <Span>{this.props.aboutSubtitle}</Span></Title>
          </CardAside>
          <Content dangerouslySetInnerHTML={{__html: this.props.aboutContent}} />
        </Card>
    )
  }
}

export default About
