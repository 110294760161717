import React from 'react'
import styled from 'styled-components'
import Carousel from './carousel'

const Wrapper = styled.section.attrs({
  id: props => props.id
})`
  padding: 72px 0 0;
  margin: 0 auto;
  text-align: center;

  @media (min-width: 768px) {
    margin: 0 auto 45px;
    width: 970px;
  }
`

const Anchor = styled.p`
  display: block;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 2px;
  margin-top: 40px;
  opacity: 0.5;
  text-transform: uppercase;

  @media (min-width: 768px) {
    margin-bottom: 78px;
  }
`

const Container = styled.div`
  @media (min-width: 748px) {
    align-items: stretch;
    display: flex;
  }
`

const NewsroomItem = styled.a.attrs({
  href: props => props.href,
  target: '_blank'
})`
  background-color: #1f1f99;
  color: #fff;
  margin-bottom: 24px;
  max-width: 317px;
  min-height: 492px;
  padding: 65px 32px 40px;
  margin: 0 4px;
  text-decoration: none;
  width: auto;

  strong {
    display: block;
    font-size: 20px;
    letter-spacing: -0.2px;
    line-height: 1.5;
    margin: 0 auto;
  }

  p{
    line-height: 1.88;
    margin: 8px 0 0;
    text-align: center;
  }

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
`

const ImageBackground = styled.div`
  align-items: center;
  background-color: #ffffff;
  border-radius: 2px;
  box-shadow: 0 11px 29px 0 rgba(0, 0, 0, 0.26);
  display: flex;
  height: 72px;
  padding: 14px 32px;
  margin: 0 auto 36px;
  max-width: 156px;
`

class Newsroom extends React.Component {
  render() {
    const settings = {
      dots: true
    };

    return (
      <Wrapper {...this.props}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="64"
          height="72"
          viewBox="0 0 64 72"
        >
          <g
            fill="none"
            fillRule="evenodd"
            stroke="#33C482"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          >
            <path d="M61.366 49.838V21.655c0-1.782-.95-3.428-2.493-4.318L34.464 3.244a4.987 4.987 0 0 0-4.987 0L5.07 17.337a4.988 4.988 0 0 0-2.493 4.318v28.183c0 1.782.95 3.429 2.493 4.319L29.477 68.25c1.544.89 3.444.89 4.987 0l24.409-14.093a4.986 4.986 0 0 0 2.493-4.319zM61.366 35.747H2.576M61.366 35.747H2.576M33.043 2.702L6.786 34.931M30.818 2.702l26.257 32.229M30.818 68.791l26.256-32.228M33.043 68.791L6.786 36.563" />
          </g>
        </svg>

        <Anchor>{this.props.NewsroomAnchor}</Anchor>
        <Container>
          <Carousel settings={settings}>
            <NewsroomItem href="https://kyvo.global/br/noticias/5-startups-sao-selecionadas-para-o-track-programa-de-aceleracao-da-visa">
              <ImageBackground>
                <img
                  alt="Logo Visa Track - Programa de aceleração da visa"
                  src="/images/grey.png"
                  srcSet="/images/grey@2x.png 2x,
                  /images/grey@3x.png 3x"
                  height="42"
                />
              </ImageBackground>
              <strong>
                {this.props.NewsroomFirstTitle}
              </strong>
              <p>
                {this.props.NewsroomFirstContent}
              </p>
            </NewsroomItem>
            <NewsroomItem href="https://fintechlab.com.br/index.php/2018/02/21/beetech-entra-para-rede-ripple-e-usa-blockchain-para-remessas-internacionais/">
              <ImageBackground>
                <img
                  alt="Logo Fintechlab"
                  src="/images/screen-shot-2018-09-27-at-13-09-13.png"
                  srcSet="/images/screen-shot-2018-09-27-at-13-09-13@2x.png 2x,
                  /images/screen-shot-2018-09-27-at-13-09-13@3x.png 3x"
                  height="45"
                />
              </ImageBackground>
              <strong>
                {this.props.NewsroomSecondTitle}
              </strong>
              <p>
                {this.props.NewsroomSecondContent}
              </p>
            </NewsroomItem>
            <NewsroomItem href="https://exame.abril.com.br/seu-dinheiro/remessas-para-o-exterior-podem-ser-mais-baratas-online/">
              <ImageBackground>
                <img
                  alt="Logo Revista Exame"
                  src="/images/exame-logo.png"
                  srcSet="/images/exame-logo@2x.png 2x,
                  /images/exame-logo@3x.png 3x"
                  height="15"
                />
              </ImageBackground>
              <strong>
                {this.props.NewsroomThirdTitle}
              </strong>
              <p>
                {this.props.NewsroomThirdContent}
              </p>
            </NewsroomItem>
          </Carousel>
        </Container>
      </Wrapper>
    )
  }
}

export default Newsroom
