import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.section.attrs({
  id: props => props.id
})`
  margin: 24px 0 0;
  padding: 62px 0 0;
  text-align: center;

  @media (min-width: 768px) {
    margin: 0 auto 45px;
    width: 425px;
  }
`

const Container = styled.div`
  text-align: center;
`

const WorkWithUsTitle = styled.h2`
  font-size: 20px;
  line-height: 1.6;
  margin-top: 0;

  @media (min-width: 768px) {
    font-size: 36px;
    line-height: 1.44;
    margin-top: 16px;
  }
`

const WorkWithUsCTA = styled.a.attrs({
  href: props => props.href,
  target: '_blank'
})`
  align-items: center;
  background-color: #3fc889;
  border: none;
  border-radius: 34px;
  color: #fff;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  justify-content: center;
  line-height: 1.43;  
  margin: 0 18px 32px; 
  padding: 16px 48px;
  text-decoration: none;
  text-transform: uppercase;

  &:hover,
  &:focus {
    background-color: #47b980;
  }

  &:active {
    background-color: #3fc889;
  }

  @media (min-width: 768px) {
    margin: 0 auto 32px;  
  }
`

const WorkWithUsMail = styled.a.attrs({
  href: props => props.href
})`
  border-bottom: solid 2px #33c482;
  color: #323756;
  display: block;
  margin: 0 auto 16px;
  max-width: 202px;
  padding: 4px;
  text-decoration: none;
  transition: all 0.5s;

  &:hover,
  &:focus {
    background-color: #33c482;
    border-color: transparent;
  }

  &:active {
    background-color: transparent;
    border-color: #33c482
  }
`

const WorkWithUsOtherLinksList = styled.div`
  margin-bottom: 40px;
`

const WorkWithUsLink = styled(WorkWithUsMail).attrs({
  target: '_blank'
})`
  display: inline-block;
  margin: 0 13px;
  width: auto;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  @media (min-width: 768px) {
    margin: 0 20px;
  }
`

const Quote = styled.p`
  line-height: 1.88;
  margin: 0 auto;
  opacity: 0.44;
  text-align: center;
  width: 75%;
`

const Hashtag = styled.span`
  color: #33c482;
  line-height: 1.88;
`

class WorkWithUs extends React.Component {
  render() {
    return (
      <Wrapper {...this.props}>
        <Container>
          <WorkWithUsTitle dangerouslySetInnerHTML={{__html: this.props.WorkWithUsTitle}} />
          <WorkWithUsCTA href="https://jobs.kenoby.com/remessaonline">{this.props.WorkWithUsCTA}</WorkWithUsCTA>
          <WorkWithUsMail href="mailto:contato@beetech.global">contato@beetech.global</WorkWithUsMail>
          <WorkWithUsOtherLinksList>
            <WorkWithUsLink
              href="https://www.linkedin.com/company/beetech.global/"
            >
              Linkedin
            </WorkWithUsLink>
            <WorkWithUsLink
              href="https://www.instagram.com/beetech.global/"
            >
              Instagram
            </WorkWithUsLink>
            <WorkWithUsLink
              href="https://github.com/BeeTech-global"
            >
              Github
            </WorkWithUsLink>
          </WorkWithUsOtherLinksList>
          <Quote>{this.props.WorkWithUsQuote}</Quote>
          <Hashtag>{this.props.WorkWithUsHashtag}</Hashtag>
        </Container>
      </Wrapper>
    )
  }
}

export default WorkWithUs
