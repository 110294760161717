import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'

import Header from '../components/header'
import Hero from '../components/hero'
import About from '../components/about'
import Partners from '../components/partners'
import Values from '../components/values'
import Products from '../components/products'
import Newsroom from '../components/newsroom'
import WorkWithUs from '../components/workwithus'
import Footer from '../components/footer'

const IndexPage = ({ pageContext: { locale }, data }) => {
  return (
    <Layout path="/" locale={locale}>
      <Header locale={locale} path="/" {...data.file.childIndexJson} />
      <Hero {...data.file.childIndexJson} />
      <About id="about" {...data.file.childIndexJson} />
      <Partners {...data.file.childIndexJson} />
      <Values id="values" {...data.file.childIndexJson} />
      <Products id="products" {...data.file.childIndexJson} />
      <Newsroom id="media" {...data.file.childIndexJson} />
      <WorkWithUs id="contact" {...data.file.childIndexJson} />
      <Footer {...data.file.childIndexJson} />
    </Layout>
  )
}

export const query = graphql`
  query Home($locale: String) {
    file(name: { eq: $locale }, relativeDirectory: { eq: "index" }) {
      childIndexJson {
        beetech
        headerAbout
        headerValues
        headerProducts
        headerNewsroom
        headerContact
        heroTitle
        heroTitleSpan
        heroCTA
        aboutAnchor
        aboutTitle
        aboutSubtitle
        aboutContent
        partnersTitle
        partnersFirstItem
        partnersSecondItem
        partnersThirdItem
        partnersFourthItem
        valuesAnchor
        valuesItemFirstTitle
        valuesItemFirstContent
        valuesItemSecondTitle
        valuesItemSecondContent
        valuesItemThirdTitle
        valuesItemThirdContent
        valuesItemFourthTitle
        valuesItemFourthContent
        valuesItemFifthTitle
        valuesItemFifthContent
        ProductsAnchor
        ProductsBeecambioTitle
        ProductsBeecambioSubtitle
        ProductsBeecambioContent
        ProductsRemessaTitle
        ProductsRemessaSubtitle
        ProductsRemessaContent
        ProductsBeeconnectTitle
        ProductsBeeconnectSubtitle
        ProductsBeeconnectContent
        ProductsForBusinessTitle
        ProductsForBusinessSubtitle
        ProductsForBusinessContent
        ProductsFXaaSTitle
        ProductsFXaaSSubtitle
        ProductsFXaaSContent
        ProductsOpenPaymentsTitle
        ProductsOpenPaymentsSubtitle
        ProductsOpenPaymentsContent
        ProductsKnowMore
        NewsroomAnchor
        NewsroomFirstTitle
        NewsroomFirstContent
        NewsroomSecondTitle
        NewsroomSecondContent
        NewsroomThirdTitle
        NewsroomThirdContent
        WorkWithUsTitle
        WorkWithUsCTA
        WorkWithUsQuote
        WorkWithUsHashtag
        FooterSiteMap
        FooterBackLink
        FooterWorkWithUsTitle
        FooterWorkWithUsText
        FooterWorkWithUsPreLink
        FooterWorkWithUsLink
        FooterCopyright
      }
    }
  }
`

export default IndexPage
