module.exports = {
  en: {
    path: 'en',
    locale: 'En'
  },
  pt: {
    path: 'pt',
    locale: 'Pt',
    default: true
  }
}