import React from "react";
import styled from "styled-components"
import {IoIosArrowRoundDown as ArrowDown} from 'react-icons/io'

const Wrapper = styled.section`
  @media (min-width: 768px) {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 60px;
  }
`
const Content = styled.div`
  margin: 0 auto;
  position: relative;
  z-index: 2;
  width: 239px;

  @media (min-width: 768px) {
    margin: 0 24px;
    max-width: 580px;
    width: auto;
  }
`

const Title = styled.h1`
  color: #1f1f99;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 288px;
  letter-spacing: -0.2px;
  width: 100%;

  @media (min-width: 768px) {
    font-size: 46px;
    letter-spacing: -0.5px;
    margin-bottom: 118px;
  }
`

const Span = styled.span`
  color: #33C482;
  display: block;
`

const Button = styled.a.attrs({
  href: props => props.href
})`
  align-items: center;
  background-color: #1f1f99;
  border: none;
  box-shadow: 0 20px 38px 0 rgba(31, 31, 153, 0.3);
  border-radius: 34px;
  color: #FFFFFF;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  justify-content: center;
  line-height: 1.43;
  margin: 0 auto 50px;
  padding: 19px 32px;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.5s;

  &:hover,
  &:focus {
    background-color: #313191;
    box-shadow: 0 10px 38px 0 rgba(31, 31, 153, 0.3);
  }

  &:active {
    background-color: #1f1f99;
    box-shadow: 0 20px 38px 0 rgba(31, 31, 153, 0.3);
  }

  @media (min-width: 768px) {
    line-height: 1.40;
    margin-bottom: 0;
    margin-left: 0;
    padding: 17.5px 29.12px;
    width: 216px;
  }
`

const ArrowIcon = styled(ArrowDown)`
  font-size: 16px;
`

const ImageWrapper = styled.div`
  @media (max-width: 768px) {
    display: block;
    height: 311px;
    margin-bottom: -310px;
    position: relative;
    top: -381px;
    width: 100vw;
    z-index: 1;

    img {
      position: absolute;
      z-index: 1;

      &:first-child {
        height: 311px;
        left: 26px;
        top: 1px;
      }

      &:nth-child(2) {
        height: 252px;
        left: 175px;
        top: 89px;
      }

      &:last-child {
        height: 211px;
        left: 69px;
        top: 233px;
      }
    }
  }

  @media (min-width: 768px) {
    height: 454px;
    position: relative;
    width: 478px;
    white-space: pre;

    img {
      position: absolute;

      &:first-child {
        top: -100px;
      }

      &:nth-child(2) {
        left: 250px;
        top: 20px;
      }

      &:last-child {
        left: 95px;
        top: 379px;
      }
    }
  }
`

class Hero extends React.Component {
  constructor(props) {
    super(props)

    this.smoothScrolling = this.smoothScrolling.bind(this)
  }

  smoothScrolling(evt, event) {
    event.preventDefault()

    const element = document
      .querySelector(evt.getAttribute('href'))

    const OFFSET = element.id === 'about' ? 100 : 96;

    window.scrollTo({
      top: (element.offsetTop - OFFSET),
      behavior: 'smooth'
    })

    const allNodes = document.getElementById('desktop-nav').children;
    Object.keys(allNodes).map(element => { // eslint-disable-line array-callback-return
      allNodes[element].classList.remove('active')

      if (evt.getAttribute('href') === allNodes[element].getAttribute('href')) {
        allNodes[element].classList.add('active')
      }
    })

    const mobileNav = document.getElementById('mobile-nav')
    mobileNav.classList.remove('show')
  }

  render() {
    return (
      <Wrapper>
        <Content>
          <Title>
            {this.props.heroTitle} <Span>{this.props.heroTitleSpan}</Span>
          </Title>
          <Button href="#about" onClick={evt => this.smoothScrolling(evt.target, evt)} >
            {this.props.heroCTA}
            <ArrowIcon />
          </Button>
        </Content>
        <ImageWrapper>
          <img
            alt="Icone de computador mostrando gráficos"
            src="/images/iphone-pe-psd.png"
            srcSet="/images/iphone-pe-psd@2x.png 2x,
              /images/iphone-pe-psd@3x.png 3x"
          />
          <img
            alt="Icone de computador mostrando gráficos"
            src="/images/iphone-deitado-psd.png"
            srcSet="/images/iphone-deitado-psd@2x.png 2x,
              /images/iphone-deitado-psd@3x.png 3x"
          />
          <img
            alt="Icone de computador mostrando gráficos"
            src="/images/i-phone-x-isometric-png.png"
            srcSet="/images/i-phone-x-isometric-png@2x.png 2x,
              /images/i-phone-x-isometric-png@3x.png 3x"
          />
        </ImageWrapper>
      </Wrapper>
    )
  }
}

export default Hero